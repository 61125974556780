import React, { useState } from "react"
import { Box, Flex, Text, Image, Heading, Grid } from "@chakra-ui/core"
import fileImage from "../../../images/file.svg"
import tickImage from "../../../images/tick.svg"
import medalImage from "../../../images/medal.svg"
import briefingImage from "../../../images/briefing.svg"
import { TriangleRight } from "@emotion-icons/entypo/TriangleRight"
import { DotCircle } from "@emotion-icons/fa-solid/DotCircle"
import SectionTitle from "../SectionTitle"
import { Fade } from "react-awesome-reveal"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

interface SirappuDataInterface {
  image: string
  title: string
  description: string
  bulletsArray: string[]
  color: string
}

const BestTeachingArray: SirappuDataInterface[] = [
  {
    title: "நவீன பயிற்சித் தொகுப்புகள்",
    description:
      "நவீன கற்பித்தல் – சமச்சீர் பாடத்திட்டம் -  முழுமையான பயிற்சித் தொகுப்புகள்",
    image: tickImage,
    bulletsArray: [
      "கற்பித்தல் முறையில் புதிய யுக்திகளுடன் நவீன தொழில்நுட்பத்தையும் கைகோர்த்து எளிய வாழ்வியல் மொழிநடையில்  ஒவ்வொரு மாணவர்களின் தேவையையும் உணர்ந்து பயிற்சியளிப்பது.",
      "உரிய இடைவெளிகளில் படித்தவற்றை நினைவூட்டல் செய்யும் வகையில் சமச்சீர் பாடத் திட்டத்தின் படி பாடத்தொகுப்புகளை கட்டமைத்திருப்பது.",
      "பின்தங்கிய மாணவர்களையும் மற்றும் தாய்மொழிவழி கல்வி கற்ற அரசுப்பள்ளி மாணவர்களையும் கருத்திற் கொண்டு பல்வேறு துறை சார்ந்த நெறியாளர்களின் ஆலோசனைப்படி  பயிற்சித் தொகுப்புகளையும் வகுப்பறைப் பாட செயல் திட்டங்களையும் வடிவமைத்திருப்பது. ",
      "ஒவ்வொரு மாணவர்களின் விருப்பப்  பாடத்திலும் அவர்களுக்கே உரிய தனித்துவ சிந்தனைத் திறன் அடிப்படையில் மேம்பாடடையச் செய்வது",
      "வினாக்களை எளிதாகக் கையாளும் வகையில் பன்முகத் தன்மையுடன் கற்றலின் வழியும் கூட்டுக் கலந்தாய்யு முறையிலும் தனித் தன்மையை உருவாக்குவது.",
      "போட்டித் தேர்வில் எதிர்கொள்ளும் சவால்களை சமாளிக்கும் வகையில் மாணவர்களின் செயல்த்திறனை முழுமையாக மேம்படுத்துவது.",
      "கோவிட் 9 போன்ற எதிர்பாராத எந்தச் சூழ்நிலையிலும் மாணவர்களின் கற்றல் திறன் தடைபடாத வகையில் தொழில்நுட்ப உதவிகளுடன் கற்றலை வழிநடத்துவது.",
      "தமிழ் வழியில் கற்ற மாணவர்களை அதிக அளவில் நாட்டின் தலைசிறந்த ஆட்சிப்பணியாளர்களாக உருவாக்குவது.",
    ],
    color: "#FF4E00C9",
  },
  {
    title: "சிறந்த நெறியாளர்கள்",
    description:
      "சிறந்த பல்துறை நெறியாளர்கள் – எளிய மொழிநடையில் கற்பித்தல் – மொழியறிவு மேம்பாடு – விளக்கவுரையுடன் கூடிய தெளிவான பயிற்று முறை",
    image: briefingImage,
    bulletsArray: [
      "தாய்மொழி வழிக் கற்றலுக்கு இணையாக ஆங்கில மொழியறிவையும் துறைசார் கலைச் சொற்களின் தேவையையும் உணர்ந்து மாணவர்களை  மொழித் தடம் வழி  மேம்படுத்துவது.",
      "ஆங்கிலத்தில் வெளியாகும் பாடக்குறிப்புத் தொடர்பான அனைத்து அண்மை விவரங்களையும் சிறந்த பல்துறை நெறியாளர்களின் ஒத்துழைப்புடன் தமிழில் மொழியாக்கம் செய்து உடனுக்குடன் வழங்குவது.",
      "ஒவ்வொரு துறை சார்ந்த பரந்த வாசிப்பனுபவத்தையும் தேர்வாளர்களுக்கிடையே ஏற்படுத்துவதன் மூலம்  துரிதமாக சுயசார்பின் அடிப்படையில் வினாக்களுக்கு விடையளிக்கும் ஆற்றலை ஏற்படுத்துவது",
      "வலுவான போட்டியாளர்களாக மாணவர்கள் தங்களது ஆளுமையை வெளிப்படுத்தும் வகையில் களாய்வுகளையும் வாய்ப்புகளையும் ஏற்படுத்திக் கொடுப்பது.",
      "தனித்துவ ஆற்றலில் முழுகவனங் கொண்டு வழிநடத்துவது",
      "கற்றலுக்கான உந்துசக்தியாகி எந்தப் போட்டித் தேர்வுகளையும் எளிதில் வெல்லும் வகையில் பல்துறை நெறியாளர்களின் நேரடிக் கற்பித்தலின் மூலம் திறமையான போட்டியாளர்களை உருவாக்குவது.",
    ],
    color: "#00CEFFA3",
  },
  {
    title: "அரசு தரத்திலான மாதிரித் தேர்வுகள்",
    description:
      "தினசரித் துரிதத் தேர்வுகள் – தேர்வில் நேர நிர்வாகம் – எளிமையாக வினாக்களைக் கையாளும் யுக்திகள் -  பொதுத் தமிழில் நூற்றுக்கு நூறு பெற சிறப்புப் பயிற்சி",
    image: medalImage,
    bulletsArray: [
      "ஆழமாக புரிந்து கொண்டு தொடர்ந்து கூர்ந்து கவனிக்கும் வகையில் கற்றலை செயல் திட்டங்களின் வாயிலாகவும் பயிற்சிகள் வாயிலாகவும் தேர்வுகளின் வழித்துணையிலும் எளிதாக்குவது.",
      "தினசரி தேர்வுகளின் மூலம் வினாக்களைக் கையாள்வதில் ஒழுங்குமுறைபடுத்திய நேர நிர்வாகத்தை ஏற்படுத்துவது.",
      "எந்தெந்தத் தளங்களிலிருந்து வினாக்களுக்கான விடைகளை பரந்து விரிந்த பறவை பார்வையுடன் பதிலளிக்கலாம் எனும் வகையில் மாணவர்களைத் தனித்தன்மையுடன்  செயலாற்ற வைப்பது.",
      "கேட்கப்படும் வினாக்களில் சிறப்புச் சொற்கள் (Key Words)  எவ்வளவு முக்கியத்துவம் வாய்ந்தது என்பதை உணர்த்தும் வகையில் வினாக்களைக் கட்டமைப்பது. சிறப்புச் சொற்களுக்கான வங்கியைக் கொண்டு நினைவூட்டல் திறனை மேம்படுத்துவது.",
      "பொதுத்தமிழில் நூற்றுக் நூறு பெறும் வகையில் மாணவர்களின் ஆற்றலை முழுமையாக வெளிக்கொணர்வது",
      "மேம்படுத்தப்பட்ட வகையில் வினாக்களைக் கையாளும் யுக்திகளை கற்றலின் வழியும் புரிந்துகொள்ளும் திறனின் அடிப்படையிலும் ஏற்படுத்துவது.",
      "நேர நிர்ணயம் செய்யப்பட்ட முறையான தேர்வின் வாயிலாக மாணவர்களின் வெளிப்பாட்டு செயல்பாட்டைத் திட்ட எல்லைக்குள் கூடுதல் கவனத்துடன் கையாள்வது.",
    ],
    color: "#FF4E00C9",
  },
  {
    title: "ஒவ்வொரு மாணவர்க்கும் தனிக்கவனம்",
    description:
      "நெறிப்படுத்துதல் – கலந்துரையாடல் -  கருத்தரங்குகள் –  நேர்முகத் தேர்விற்காகத் தயார்படுத்துதல் - ஒவ்வொரு மாணவர்கள் மீதும் தனிக் கவனம் – தேவைப்படும் தமிழ்வழி அரசுப் பள்ளி மாணவர்களுக்கு கூடுதல் கவனம்",
    image: fileImage,
    bulletsArray: [
      "நெறியாளர்களின் கூட்டு முயற்சியில் போட்டித் தேர்வாளர்களுக்கான பாடவரையறைக்குள் கற்றலின் மேம்பாட்டுக்கான வழிமுறைகளின்படி நெறிமுறைப்படுத்துவது.",
      "மிகவும் கடினமாக பாட விவரங்களையும் எளிய பதத்தில் தேர்வாளர்களின் புரிதலின் தன்மையை விரிவுபடுத்தும் வகையில் நெறிமுறைப்படுத்தப்பட்ட கலந்துரையாடல் வழி வகுப்புகளை அமைப்பது. ",
      "துறைசார் வல்லுநர்களின் துணையுடன் நடப்பு நிகழ்வுகளின் மீதான கவன ஈர்ப்பை பூர்த்திசெய்யும் வகையில் விரிவான கருத்தரங்குகளை நடத்தி முழுமையான கற்றலுக்கு வழிவகுப்பது. ",
      "நேர்முகத் தேர்வுக்கான ஆயத்தங்களை ஒவ்வொரு தருணத்திலும் தேர்வாளர்களை வெளிப்படச் செய்வது.",
      "ஒவ்வொரு மாணவர்கள் மீதான தனிக் கவனத்துடன் செயலாற்றுவது. தேவைப்படும் தமிழ்வழி அரசுப்பள்ளி மாணவர்களுக்கு கூடுதல் கவனமெடுப்பது. அவர்களின் வெற்றிக்கு துணைசெய்வது. ",
    ],
    color: "#00CEFFA3",
  },
]

interface HeadingCardProps {
  image: string
  title: string
  description: string
  active?: boolean
  border?: boolean
}

const DecoratedImage = ({ image }) => {
  return (
    <Box
      m={3}
      w={["80px", null, "90px"]}
      borderRadius="100%"
      p={1}
      bg="#ECECEC"
    >
      <Flex
        align="center"
        justify="center"
        w={["70px", null, "80px"]}
        h={["70px", null, "80px"]}
        p={4}
        bg="#DBDBDB"
        borderRadius="100%"
      >
        <Image src={image} alt="sirappu" />
      </Flex>
    </Box>
  )
}

const DesktopHeadingCard = (props: HeadingCardProps) => {
  return (
    <Flex
      display={["none", null, "flex"]}
      py={4}
      px={1}
      pr={5}
      align="center"
      borderBottom={props.border ? "1px solid #70707034" : "none"}
      position="relative"
      bg="white"
    >
      <Box>
        <DecoratedImage image={props.image} />
      </Box>
      <Box>
        <Heading p={1} fontSize="2xl" mb={4} fontWeight="bold">
          {props.title}
        </Heading>
        <Text
          fontSize="sm"
          display={["none", null, "block"]}
          color="#606060"
          p={1}
        >
          {props.description}
        </Text>
      </Box>
      {props.active && (
        <Box
          position="absolute"
          right={0}
          top="50%"
          transform="translate(70%, -50%)"
          p={0}
          zIndex={1}
        >
          <TriangleRight size="4rem" color="#fff" />
        </Box>
      )}
    </Flex>
  )
}

const MobileHeadingCard = (props: HeadingCardProps): JSX.Element => {
  const { image, title } = props
  return (
    <Flex
      flex="0 0 auto"
      direction="column"
      align="center"
      border="1px solid #70707034"
      mx="auto"
      p={3}
      display={["flex", null, "none"]}
      boxShadow={
        props.active
          ? "0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22)"
          : "none"
      }
    >
      <DecoratedImage image={image} />
      <Heading textAlign="center" fontSize="lg">
        {title}
      </Heading>
    </Flex>
  )
}

const BestTeachingSection = () => {
  const [activeIndex, setActiveIndex] = useState(0)

  const currentItem = BestTeachingArray[activeIndex]

  const data = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "library.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Box mx={5} my={12}>
      <Box mt={[0, 8, 16]} mb={[5, 16, 20]}>
        <SectionTitle title="ஆட்சித்தமிழின் சிறப்பு" />
      </Box>
      <Grid
        templateColumns={["1fr", null, null, "1fr 2fr"]}
        mx="auto"
        mt={12}
        maxW="1150px"
        boxShadow="rgba(0, 0, 0, 0.08) 0px 6px 10px"
        gap={1}
      >
        <Grid templateColumns={["repeat(2, minmax(0, 1fr))", null, "1fr"]}>
          {BestTeachingArray.map((item, index) => {
            return (
              <Box
                key={index}
                cursor="pointer"
                onClick={() => {
                  setActiveIndex(index)
                }}
              >
                <DesktopHeadingCard
                  image={item.image}
                  title={item.title}
                  description={item.description}
                  active={index === activeIndex}
                  border={!(index === BestTeachingArray.length - 1)}
                />
                <MobileHeadingCard
                  image={item.image}
                  title={item.title}
                  description={item.description}
                  active={index === activeIndex}
                />
              </Box>
            )
          })}
        </Grid>
        <BackgroundImage
          Tag="section"
          fluid={data.bgImage.childImageSharp.fluid}
        >
          <Box p={[3, 8, 16]} h="100%" fontWeight="bold" bg={currentItem.color}>
            <Fade>
              <Box p={6} color="#fff" fontWeight="bold">
                <Heading fontSize={["3xl", "4xl", "6xl"]}>
                  {currentItem.title}
                </Heading>
              </Box>
              <Text
                fontSize={["lg", null, "xl"]}
                lineHeight="2"
                color="#fff"
                py={4}
                px={5}
              >
                {currentItem.description}
              </Text>
              <Box px={5}>
                {currentItem.bulletsArray.map((item, index) => (
                  <Flex key={index} color="#fff" my={3}>
                    <Box>
                      <DotCircle height="20px" width="20px" />
                    </Box>
                    <Text mt={-1} ml={2}>
                      {item}
                    </Text>
                  </Flex>
                ))}
              </Box>
            </Fade>
          </Box>
        </BackgroundImage>
      </Grid>
    </Box>
  )
}
export default BestTeachingSection
