import React from "react"
import {
  Box,
  Flex,
  Text,
  Input,
  Image,
  Heading,
  Stack,
  Textarea,
} from "@chakra-ui/core"
import fullStar from "../../images/star-fill.svg"
import halfStar from "../../images/star-half.svg"
import QuoteIcon from "../../images/quoteBlue.svg"
import TickMarkIcon from "../../images/tickmark.svg"
import Logo from "../../images/shortLogo.png"
import RegisterBg from "../../images/bluebg.svg"
import Button from "./Button"
import { Fade, Zoom } from "react-awesome-reveal"
interface DetailsSectionProps {
  detailsArray: string[]
}

const RatingSection = (): JSX.Element => {
  return (
    <Flex justify="center">
      <Image maxW="1.5rem" src={fullStar} />
      <Image maxW="1.5rem" src={fullStar} />
      <Image maxW="1.5rem" src={fullStar} />
      <Image maxW="1.5rem" src={fullStar} />
      <Image maxW="1.5rem" src={halfStar} />
    </Flex>
  )
}

const DetailsSection = (props: DetailsSectionProps) => {
  return (
    <Box mt={48} mb={[0, null, 20]} maxW="400px" mx="auto">
      <Fade cascade duration={100} direction="left" triggerOnce>
        {props.detailsArray.map((item, index) => {
          return (
            <Stack
              key={index}
              isInline
              align="center"
              spacing={2}
              px={2}
              py={2}
              color="#2E3292"
              fontWeight="bold"
              fontSize="sm"
            >
              <Image height="1.3rem" src={TickMarkIcon} />
              <Box>{item}</Box>
            </Stack>
          )
        })}
      </Fade>
    </Box>
  )
}

const ReviewSection = () => {
  return (
    <Box mt={48} mx="auto">
      <Fade triggerOnce duration={500} direction="right">
        <Box
          p={4}
          my={4}
          border="1px solid #5057FA27"
          borderRadius="1em"
          minW="200px"
          maxW="400px"
        >
          <Image src={QuoteIcon} />
          <RatingSection />
          <Box
            fontSize="lg"
            textAlign="center"
            fontWeight="bold"
            color="#2E3292"
            py={2}
          >
            <Text px={4}>
              Excellent Coaching! Always there to clear my doubts
            </Text>
            <Text>- Priya</Text>
          </Box>
        </Box>
        <Box
          p={4}
          my={4}
          border="1px solid #5057FA27"
          borderRadius="1em"
          minW="200px"
          maxW="400px"
        >
          <Image ml="auto" src={QuoteIcon} transform="rotate(180deg)" />
          <RatingSection />
          <Box
            fontSize="lg"
            textAlign="center"
            fontWeight="bold"
            color="#2E3292"
            py={2}
          >
            <Text>Study materials are excellent!</Text>
            <Text>- Ashwin</Text>
          </Box>
        </Box>
      </Fade>
    </Box>
  )
}

const FormSection = (): JSX.Element => {
  return (
    <Box mx="auto" mt={[3, null, null, 0]} textAlign="center" maxW="500px">
      <Zoom triggerOnce duration={500}>
        <Box
          p={6}
          pb={12}
          boxShadow="0 6px 10px rgba(0,0,0,.08)"
          borderRadius="2em"
          bg="white"
        >
          <Heading color="#2E3292" fontSize="5xl">
            பதிவு செய்க
          </Heading>
          <Image mx="auto" src={Logo} alt="logo" h="100px" />
          <Box mt={3}>
            <form
              id="register"
              name="register"
              method="post"
              data-netlify="true"
              action="/ThankYou/register"
            >
              <Stack isInline spacing={6} mx="auto">
                <Input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="முதல் பெயர்"
                  fontSize="xl"
                  isRequired
                />
                <Input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="கடைசி பெயர்"
                  fontSize="xl"
                  isRequired
                />
              </Stack>
              <Box mt={6}>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="மின்னஞ்சல் முகவரி"
                  fontSize="xl"
                  isRequired
                />
              </Box>
              <Box mt={6}>
                <Input
                  type="text"
                  name="mobile"
                  id="mobile"
                  placeholder="கைபேசி எண்"
                  fontSize="xl"
                  isRequired
                />
              </Box>
              <Box mt={6}>
                <Textarea
                  name="additionalQueries"
                  id="additionalQueries"
                  placeholder="கூடுதல் கருத்துரைகள்"
                  fontSize="xl"
                />
              </Box>
              <Button
                bg="#0CBF5F"
                mt={10}
                px={20}
                py={2}
                color="white"
                borderRadius="4em"
                fontSize="xl"
                type="submit"
              >
                சமர்ப்பிக்க
              </Button>
            </form>
          </Box>
        </Box>
      </Zoom>
    </Box>
  )
}

const RegisterSection = () => {
  return (
    <Flex
      id="register"
      pt={[0, null, 48]}
      pb={20}
      px={5}
      flexWrap="wrap"
      align="center"
      position="relative"
    >
      <Box position="absolute" top={0} left={0} zIndex={-1}>
        <Image src={RegisterBg} />
      </Box>
      <Box display={["none", null, null, null, "block"]}>
        <DetailsSection
          detailsArray={[
            "தரமான பயிற்சித் தொகுப்புகள்",
            "பாடவாரியான பயிற்சித்தேர்வுகள்",
            "அரசு தரத்திலான மாதிரித் தேர்வுகள்",
            "குரூப்-4 தேர்வில் பொதுத்தமிழ் பாடத்தில் நூத்துக்கு நூறு மதிப்பெண்கள் பெற சிறப்புப் பயிற்சி",
            "கணக்கு மற்றும் புத்திக்கூர்மை பாடத்திற்கு விளக்கமான மற்றும் சுருக்க வகுப்புகள்",
            "அனுபவமிக்க ஆசிரியர்களால் மிகச் சிறந்த பயிற்சி",
            "14 ஆண்டுகளுக்கும் மேலான கற்பித்தல் அனுபவம்",
            "தமிழ்வழி மாணவர்களுக்கு தனிக்கவனம்",
            "அரசுப்பள்ளி மாணவர்களுக்கு கூடுதல் கவனம்",
          ]}
        />
      </Box>
      <FormSection />
      <Box display={["none", null, null, null, "block"]}>
        <ReviewSection />
      </Box>
    </Flex>
  )
}
export default RegisterSection
